/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

a,
u {
    text-decoration: none;
}

.bar-of-progress {
    z-index: 50;
}

.body-bodyContainer {
    max-width: 100%;
    margin: auto;
    margin-bottom: 10px;
    margin-top: 20px;
    /* overflow: hidden; */
}
.body-container .body {
    position: relative;
    text-overflow: wrap;
    margin: auto;
    font-size: 14px !important;
    font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    color: #333 !important;
    line-height: 1.7 !important;
}

.body-container .body p {
    font-size: 14px !important;
    font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    color: #333 !important;
    font-weight: 400 !important;
    line-height: 1.8 !important;
    text-align: justify;
}
.body-container .body li {
    font-size: 14px !important;
    font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    color: #333 !important;
    font-weight: 400 !important;
    line-height: 1.8 !important;
    text-align: justify;
}
.body-container .body .image.image-style-align-right {
    width: 48%;
    float: right;
    padding: 0px 0px 20px 20px;
}
.body-container .body .image.image-style-align-left {
    width: 48%;
    float: left;
    padding: 0px 20px 20px 0px;
}
.body-container .body figure {
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    width: 100%;
}
.body-container .body .image {
    /* position: absolute; */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.body-container .body img {
    width: 100%;
}
.body-container .body table,
.body-container .body th,
.body-container .body td {
    margin: auto;
    padding: 10px;
    line-height: 14px;
    font-family: Arial, Helvetica, sans-serif;
    border: 1px solid #ebebeb;
    border-collapse: collapse;
    text-align: justify;
}
.ck-blurred {
    border: 1px solid black !important;
    border-radius: 5px;
}
.ck-focused {
    background-color: #fff;
}
.ck-body-wrapper {
    z-index: 3000;
    display: block;
    position: absolute;
}
.ytp-large-play-button ytp-button {
    display: none !important;
}
